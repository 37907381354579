<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>문제행동VMS(Voice Message Service)</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main>
                <div class="jh-info-box is-bg-white is-border-red">
                    <div class="jh-info-txt is-fill is-red">급한 주문시 먼저 주문접수를 도와주고, 문제행동을 멈추지 않는 경우 주문요청을 도와드릴 수 없음을 고지해주세요. (주문 및  그에 상응하는 급한 용무만 해당)</div>
                    <div class="jh-info-txt is-fill is-red is-mt-5">그 이외 업무시 문제행동을 멈추도록 요청하였으나, 멈추지 않는 경우 단계별 대응을 하여 주시기 바랍니다. (1단계 → 2단계 → 3단계 대응 후 <b>VMS실행 클릭</b>)</div>
                    <div class="jh-info-txt is-fill is-red is-mt-5">VMS 실행시 고객과의 통화는 자동종료되며, 고객에게는 각 행동유형에 맞는 멘트가 play 된 후  종료됩니다.</div>
                </div>
                <div class="jh-cols is-mt-15">
                    <div class="jh-form-wrap">
                        <div class="jh-ui-header is-txt-center">
                            <h1>욕설 · 폭언</h1>
                        </div>
                        <table class="jh-tbl-detail is-view">
                            <colgroup>
                                <col width="60px">
                                <col>
                            </colgroup>
                            <tbody>
                                <tr style="height: 110px;">
                                    <th class="is-org-step-01"><label>1단계</label></th>
                                    <td class="is-org-step-01"> 
                                        고객님, 화가 나셨겠지만 마음을 가라앉히시고<br>차분히 말씀해 주시기 바랍니다.<br>
                                        계속적으로 고성이나 욕설, 폭언을 하시면 더 이상<br>상담진행이 불가함을 안내드립니다.
                                    </td>
                                </tr>
                                <tr style="height: 110px;">
                                    <th class="is-org-step-02"><label>2단계</label></th>
                                    <td class="is-org-step-02"> 
                                        고객님, 계속해서 욕설을 하시면 상담이 어렵습니다
                                        고객님의 행위는 상대방에게 모욕감과 공포심을<br>일으키는 위법행위로 관련 법령에 저촉될 수 있으며,<br>
                                        계속해서 욕설/폭언/고성을 하시면 상담이 종료될 수<br>있으니 자제부탁드립니다.
                                    </td>
                                </tr>
                                <tr style="height: 100px;">
                                    <th class="is-org-step-03"><label>3단계</label></th>
                                    <td class="is-org-step-03"> 
                                        고객님, 더 이상 고객님의 업무를 도와드릴 수가<br>없습니다.<br>
                                        죄송합니다만, 고객님에 대한 상담업무는 종료되며<br>관련 법규는 ARS를 연결하여 안내해 드리겠습니다.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="jh-btn-area is-mt-10 is-mb-10"><v-btn class="jh-btn is-lg is-org" style="width: 180px;" @click="click1">욕설 · 폭언 VMS 실행</v-btn></div>
                    </div>
                    <div class="jh-form-wrap">
                        <div class="jh-ui-header is-txt-center">
                            <h1>성희롱</h1>
                        </div>
                        <table class="jh-tbl-detail is-view">
                            <colgroup>
                                <col width="60px">
                                <col>
                            </colgroup>
                            <tbody>
                                <tr style="height: 110px;">
                                    <th class="is-red-step-01"><label>1단계</label></th>
                                    <td class="is-red-step-01"> 
                                        고객님, 문의하고자 하시는 내용에 대해서만<br>말씀해주시기 바랍니다.<br>
                                        계속해서 성적발언을 하시면 더이상 상담진행이<br>불가함을 안내드립니다.
                                    </td>
                                </tr>
                                <tr style="height: 110px;">
                                    <th class="is-red-step-02"><label>2단계</label></th>
                                    <td class="is-red-step-02"> 
                                        고객님의 행위는 상대방에게 성적 수치심이나<br>혐오감을 일이키는 위법행위로 관련 법령에 저촉될<br>수 있습니다.<br>
                                        성적 발언등을 하시면 법적조치를 할 수 있으니 자제<br>부탁드립니다.
                                    </td>
                                </tr>
                                <tr style="height: 100px;">
                                    <th class="is-red-step-03"><label>3단계</label></th>
                                    <td class="is-red-step-03">  
                                        고객님, 더이상 고객님의 업무를 도와드릴 수가<br>없습니다.<br>
                                        죄송합니다만, 고객님에 대한 상담업무는 종료되며<br>관련 법규는 ARS를 연결하여 안내해 드리겠습니다.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="jh-btn-area is-mt-10 is-mb-10"><v-btn class="jh-btn is-lg is-red" style="width: 180px;" @click="click2">성희롱 VMS 실행</v-btn></div>
                    </div>
                    <div class="jh-form-wrap">
                        <div class="jh-ui-header is-txt-center">
                            <h1>문제행동(반복적 민원제기 등)</h1>
                        </div>
                        <table class="jh-tbl-detail is-view">
                            <colgroup>
                                <col width="60px">
                                <col>
                            </colgroup>
                            <tbody>
                                <tr style="height: 110px;">
                                    <th class="is-purple-step-01"><label>1단계</label></th>
                                    <td class="is-purple-step-01"> 
                                        고객님, 현재 문제 해결을 위해 최선을 다하였으나<br>도움을 드리지 못해 죄송합니다.<br>
                                        동일한 사안에 대해 반복적으로 요청하셔도 동일한<br>처리방법과 답변을 드릴 수 밖에 없습니다.<br>양해부탁드립니다.
                                    </td>
                                </tr>
                                <tr style="height: 110px;">
                                    <th class="is-purple-step-02"><label>2단계</label></th>
                                    <td class="is-purple-step-02"> 
                                        고객님, 죄송합니다. 동일 사안에 대해 반복적으로<br>요청하셔도 현재로서는 동일한 처리방법과 답변들<br>드릴 수 밖에 없사오니 양해해주시길 부탁드립니다.<br> 
                                        다시 한번 도움드리지 못해 죄송합니다.
                                    </td>
                                </tr>
                                <tr style="height: 100px;">
                                    <th class="is-purple-step-03"><label>3단계</label></th>
                                    <td class="is-purple-step-03"> 
                                        고객님, 더이상 고객님의 업무를 도와드릴 수가<br>없습니다. <br>
                                        죄송합니다만, 고객님에 대한 상담업무는 종료하도록<br>하겠습니다.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="jh-btn-area is-mt-10 is-mb-10"><v-btn class="jh-btn is-lg is-purple" style="width: 180px;" @click="click3">문제행동 VMS 실행</v-btn></div>
                    </div>
                </div>
            </main>
            <!-- <footer><v-btn class="jh-btn is-md is-main" @click="close">선택</v-btn></footer> -->
        </div>
    </v-app>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { eventBus } from "@/store/eventBus.js";
import { mapGetters } from "vuex";

export default {
    name: "MENU_P100801", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    components: {
        dataTables
    },
    data() {
        return {
        };
    },
    created() {
    },
    mounted(){
        console.log(this.gePlayYN);
    },
    computed: {
        ...mapGetters({
            gePlayYN: 'callStore/GE_PLAY_YN',
            geCallStt: 'callStore/GE_CALL_STATE'
        }),
    },
    watch: {
    },
    methods: {
        close(){
            self.close();
        },
        sendMsgToParent(msg, val) {
            this.sendToOpener({
                evt:  msg,
                Val1: val,
                message: 'P100801'
            });
        },
        sendToOpener( sendObj ) {
            if( opener == null) {
                return;
            }
            let sendStr = JSON.stringify(sendObj);
            window.opener.postMessage( sendStr, '*');
        },
        calledFromOpener( evt ) {
            this.message = evt.msg;
        },
        click1() {
            // 욕설 · 폭언 VMS
            if (this.geCallStt == 'TALKING') {
                if (this.gePlayYN == 'N') {
                    this.sendMsgToParent("CONSULT2", "65130");
                    this.close();
                } else if (this.gePlayYN == 'Y') {
                    this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요.`);
                }
            } else {
                this.common_alert(`통화중 상태에서만 문제행동 VMS \n 요청이 가능합니다.`, 'info');
            }
        },
        click2() {
            // 성희롱 VMS
            if (this.geCallStt == 'TALKING') {
                if (this.gePlayYN == 'N') {
                    this.sendMsgToParent("CONSULT2", "65131");
                    this.close();
                } else if (this.gePlayYN == 'Y') {
                    this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요.`);
                }
            } else {
                this.common_alert(`통화중 상태에서만 문제행동 VMS \n 요청이 가능합니다.`, 'info');
            }
        },
        click3() {
            // 동일민원반복
            if (this.geCallStt == 'TALKING') {
                if (this.gePlayYN == 'N') {
                    this.sendMsgToParent("CONSULT2", "65132");
                    this.close();
                } else if (this.gePlayYN == 'Y') {
                    this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요.`);
                }
            } else {
                this.common_alert(`통화중 상태에서만 문제행동 VMS \n 요청이 가능합니다.`, 'info');
            }
        },
        calledFromOpener( evt ) {
            console.log('문제행동VMS');
            console.log(evt);
            this.$store.dispatch("callStore/AC_PLAY_YN", evt.msg);
        },
    },
};
</script>

<style></style>